
.modal-backdrop {
    opacity: 0.6 !important;
}

* {
    font-family: "Montserrat";
    font-weight: 400;
}

.Hoverable:hover {
    cursor: pointer;
}

